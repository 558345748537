<template>
  <div style="padding: 30px">
    <div>
      <el-select v-model="form.certificateId" placeholder="请选择">
        <el-option
          v-for="item in tokenList"
          :key="item.certificateId"
          :label="item.tokenName"
          :value="item.certificateId"
        >
        </el-option>
      </el-select>
      <el-button @click="onSearch" type="primary" style="margin: 0 20px"
        >搜索</el-button
      >
    </div>
    <div style="margin: 50px 0">
      <el-table :data="formData.tableData">
        <el-table-column
          label="通证ID"
          align="center"
          prop="certificateId"
        ></el-table-column>
        <el-table-column
          label="收盘时间"
          align="center"
          prop="date"
        ></el-table-column>
        <el-table-column
          label="当天收盘价"
          align="center"
          prop="price"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          prop="createTime"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="handleSizeChange">
      <el-pagination
        layout="prev, pager, next"
        background
        :total="formData.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        certificateId: "", // 通证ID
        currentPage: 1,
        pageSize: 10,
      },
      formData: {
        total: 0,
        tableData: [],
      },
      tokenList: [], //通证下拉框
    };
  },
  mounted() {
    this.getDataList();
    this.getToken();
  },
  methods: {
    getDataList() {
      this.axios.get("/admin/closePrice/adminShow", this.form).then((res) => {
        // console.log(res);
        console.log(res);
        this.formData.total = res.data.data.total;
        this.formData.tableData = res.data.data.records;
      });
    },
    // 获取通证框
    getToken() {
      this.axios.get("/admin/certificate/getDropDownBox").then((res) => {
        this.tokenList = res.data.data;
        // console.log(this.tokenList);
      });
    },
    // 分页
    handleCurrentChange(e) {
      this.form.currentPage = e;
      this.getDataList();
    },
    // 搜索
    onSearch() {
      this.form.currentPage = 1;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.handleSizeChange {
  text-align: center;
  margin: 100px 0 10px 0;
}
</style>
